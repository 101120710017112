// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-rooms-index-js": () => import("./../../../src/pages/rooms/index.js" /* webpackChunkName: "component---src-pages-rooms-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-amenities-page-js": () => import("./../../../src/templates/amenities-page.js" /* webpackChunkName: "component---src-templates-amenities-page-js" */),
  "component---src-templates-blog-post-gallery-js": () => import("./../../../src/templates/blog-post-gallery.js" /* webpackChunkName: "component---src-templates-blog-post-gallery-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-rooms-js": () => import("./../../../src/templates/blog-post-rooms.js" /* webpackChunkName: "component---src-templates-blog-post-rooms-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-dandelion-page-js": () => import("./../../../src/templates/dandelion-page.js" /* webpackChunkName: "component---src-templates-dandelion-page-js" */),
  "component---src-templates-directions-page-js": () => import("./../../../src/templates/directions-page.js" /* webpackChunkName: "component---src-templates-directions-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-oh-taco-page-js": () => import("./../../../src/templates/oh-taco-page.js" /* webpackChunkName: "component---src-templates-oh-taco-page-js" */),
  "component---src-templates-rooftop-bar-page-js": () => import("./../../../src/templates/rooftop-bar-page.js" /* webpackChunkName: "component---src-templates-rooftop-bar-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

